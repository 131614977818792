/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Button, Image, PriceWrap, SeparateLine, SeparateLineWrap, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-x9eb8p bg--center pb--30" name={"introduction"} style={{"paddingTop":70}} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":10}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--48 w--300 subtitle-box--invert ls--001" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">Our grand wedding</span>"}>
              </Subtitle>

              <Title className="title-box ff--3 fs--72 w--900 title-box--invert swpf--uppercase ls--08" style={{"marginTop":12}} content={"Anna & Thomas"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 pt--0" style={{"marginTop":50,"paddingTop":null}} columns={"3"}>
            
            <ColumnWrapper className="pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box fs--36 w--300 ls--004" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">Find yourself in the photos</span>"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":25,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

              <Button className="btn-box fs--24 w--600" href={"#photogallery"} style={{"marginTop":15}} content={"Photogallery"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box w--300 ls--004" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">When should I arrive?</span>"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":25,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

              <Button className="btn-box fs--24 w--600" href={"#program"} style={{"marginTop":15}} content={"Schedule"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box fs--36 w--300 ls--004" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">Wish to leave us a message?</span>"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":25,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

              <Button className="btn-box fs--24 w--600" href={"#contact"} style={{"marginTop":15}} content={"Leave a message"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--30 mt--40" name={"about-us"} style={{"marginTop":null,"paddingTop":null,"paddingLeft":null,"marginBottom":null,"paddingBottom":null}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--0 pb--0 pl--40 pr--40" style={{"maxWidth":null,"paddingLeft":null,"marginBottom":null,"paddingRight":null,"paddingBottom":null}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--36 w--900 ls--004" content={"Hi! We're Anna & Thomas"}>
              </Title>

              <Text className="text-box fs--18 w--600 lh--16" style={{"marginTop":20}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"photogallery"} style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} layout={"l12"}>
          
          <ColumnWrap className="column__flex --full --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--20" name={"information"} style={{"marginTop":0,"paddingTop":64,"paddingLeft":null,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(246, 246, 246, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --full --center el--1 pl--40 pr--40" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--36 w--300 ls--004 mt--0 pb--0 pt--0" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">You can look forward to</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"information-2"} style={{"paddingTop":3,"paddingBottom":80,"backgroundColor":"rgba(246, 246, 246, 1)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex el--4 pl--40 pr--40" style={{"paddingLeft":null,"paddingRight":null}} columns={"4"}>
            
            <ColumnWrapper className="column__flex--top --center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/b6695e0a626a44bb9c3fa639f8535105.svg"} svg={false} style={{"maxWidth":140}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style='font-style: italic; color: rgb(0, 0, 0);'>Guest arrival</span>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600" style={{"marginTop":8}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="column__flex--top --center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/d996d9080be947789a2ec065ff964959.svg"} svg={false} style={{"maxWidth":140}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">\tOpening words</span>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600" style={{"marginTop":8}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="column__flex--top --center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/aebd6fb2601543a28d89f03da3bcdd90.svg"} svg={false} style={{"maxWidth":140}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">\tCake cutting</span>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600" style={{"marginTop":8}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="column__flex--top --center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/d76e5956fada40f0a9ede4ec0c3db013.svg"} svg={false} style={{"maxWidth":140}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">\tFirst dance</span>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600" style={{"marginTop":8}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center" name={"program"} style={{"marginTop":8,"paddingTop":40,"paddingBottom":60,"backgroundColor":"rgba(254, 254, 254, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":10}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 w--300 ls--004" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":10}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">Schedule</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --full --center el--4 pl--40 pr--40 pt--0" style={{"marginTop":50,"paddingTop":null,"paddingLeft":null,"paddingRight":null}} columns={"4"}>
            
            <ColumnWrapper className="--hvr2 --style2 --shadow2 pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Text className="text-box fs--24 w--600 pl--0 pr--0" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null}} content={"14:00"}>
              </Text>

              <Title className="title-box ff--3 fs--26 ls--004 pl--0 pr--0" style={{"marginTop":5,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"Guest arrival"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":5,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --style2 --shadow2 pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Text className="text-box fs--24 w--600 pl--0 pr--0" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null}} content={"15:00"}>
              </Text>

              <Title className="title-box ff--3 fs--26 ls--004 pl--0 pr--0" style={{"marginTop":5,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"Opening words"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":5,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --style2 --shadow2 pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Text className="text-box fs--24 w--600 pl--0 pr--0" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null}} content={"16:00"}>
              </Text>

              <Title className="title-box ff--3 fs--26 ls--004 pl--0 pr--0" style={{"marginTop":5,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"Cake cutting"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":5,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --style2 --shadow2 pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Text className="text-box fs--24 w--600 pl--0 pr--0" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null}} content={"17:00"}>
              </Text>

              <Title className="title-box ff--3 fs--26 ls--004 pl--0 pr--0" style={{"marginTop":5,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"Opening words"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":5,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --style2 --shadow2 pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Text className="text-box fs--24 w--600 pl--0 pr--0" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null}} content={"18:00"}>
              </Text>

              <Title className="title-box ff--3 fs--26 ls--004 pl--0 pr--0" style={{"marginTop":5,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"Cake cutting"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":5,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --style2 --shadow2 pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Text className="text-box fs--24 w--600 pl--0 pr--0" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null}} content={"19:00"}>
              </Text>

              <Title className="title-box ff--3 fs--26 ls--004 pl--0 pr--0" style={{"marginTop":5,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"Cake cutting"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":5,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --style2 --shadow2 pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Text className="text-box fs--24 w--600 pl--0 pr--0" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null}} content={"20:00"}>
              </Text>

              <Title className="title-box ff--3 fs--26 ls--004 pl--0 pr--0" style={{"marginTop":5,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"First dance"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":5,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --style2 --shadow2 pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Text className="text-box fs--24 w--600 pl--0 pr--0" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null}} content={"20:00"}>
              </Text>

              <Title className="title-box ff--3 fs--26 ls--004 pl--0 pr--0" style={{"marginTop":5,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"Cake cutting"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":5,"marginBottom":0}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"items"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(246, 246, 246, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36 w--300 ls--004" style={{"marginTop":50}} content={"<span style=\"font-style: italic; color: rgb(51, 47, 70);\">Gift registry</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"items-2"} style={{"backgroundColor":"rgba(246, 246, 246, 1)"}}>
          
          <ColumnWrap className="column__flex el--2 --full pl--40 pr--40" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"rgba(49, 49, 49, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 w--600 text-box--invert" content={"<span style=\"color: rgb(255, 255, 255);\">Fine china</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--20 w--600" content={"Still lacking"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap >
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 w--600" content={"Fine china"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--20 w--600" content={"Taken care of. Thanks!"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"rgba(49, 49, 49, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 w--600 text-box--invert" content={"<span style=\"color: rgb(255, 255, 255);\">Fine china</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--20 w--600" content={"Still lacking"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"rgba(49, 49, 49, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 w--600 text-box--invert" content={"<span style=\"color: rgb(255, 255, 255);\">Fine china</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--20 w--600" content={"Still lacking"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"rgba(49, 49, 49, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 w--600 text-box--invert" content={"<span style=\"color: rgb(255, 255, 255);\">Fine china</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--20 w--600" content={"Still lacking"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"rgba(49, 49, 49, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 w--600 text-box--invert" content={"<span style=\"color: rgb(255, 255, 255);\">Fine china</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--20 w--600" content={"Still lacking"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"rgba(49, 49, 49, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 w--600 text-box--invert" content={"<span style=\"color: rgb(255, 255, 255);\">Fine china</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--20 w--600" content={"Still lacking"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"rgba(49, 49, 49, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 w--600 text-box--invert" content={"<span style=\"color: rgb(255, 255, 255);\">Fine china</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--20 w--600" content={"Still lacking"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"photogallery"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/37/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/37/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/37/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/37/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/37/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"divider"} style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}} layout={"l4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"photogallery-2"} style={{"paddingTop":30,"paddingBottom":60}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36 w--300 ls--004" content={"<span style=\"font-style: italic;\">All 642 photos from the wedding can be downloaded</span>"}>
              </Title>

              <Button className="btn-box fs--20 w--600" href={"#"} style={{"marginTop":20}} content={"Download (1,5GB)"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-x9eb8p bg--bottom --parallax pl--20" name={"picture"} style={{"marginTop":0,"paddingTop":90,"paddingLeft":null,"marginBottom":0,"paddingBottom":50}} layout={"l1"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/37/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --full --center el--1 mb--0 pl--40 pr--40" style={{"paddingLeft":null,"marginBottom":null,"paddingRight":null,"paddingBottom":20}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--2 fs--72 w--300 title-box--invert ls--004" content={"<span style=\"font-style: italic;\">We look forward to seeing you!</span>"}>
              </Title>

              <Text className="text-box fs--20 w--600 text-box--invert lh--16" style={{"maxWidth":700,"marginTop":20}} content={"Click and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"contact"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36 w--300" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"font-style: italic;\">We look forward to seeing you!</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style2 --right" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit","align":"center"}]} layout={"l3"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"footer"} style={{"backgroundColor":"var(--color-variable-1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--20 w--600" content={"Created with <a style='color:#fff' href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}